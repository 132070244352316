// Translated
<template lang="pug">
AuthForm.login-page.d-flex.flex-column.align-items-center
  .login-form
    AuthFeedback(minimal)

    template(v-if="stageIsEmail")
      p(data-i18n="memberForgotPasswordIntroduction") {{ $t('memberForgotPasswordIntroduction') }}
      TextInput(id="email", v-model="email", :placeholder="$t('formEmail')", :v$="v$")
        template(#label)
          span(data-i18n="formEmail") {{ $t('formEmail') }}
        template(#validation="v")
          span(v-if="v?.v.required.$invalid && email", data-i18n="formFieldRequiredError") {{ $t('formFieldRequiredError') }}
          span(v-else-if="v?.v.$error && email", data-i18n="formFieldInvalidEmail") {{ $t('formFieldInvalidEmail') }}
      SubmitButton#login-submit(:disabled="emailLoading", :loading="emailLoading", data-i18n="memberFindMyAccount", @click="findAccountByEmail") {{ $t('memberFindMyAccount') }}

    template(v-else-if="stageIsResend")
      p(data-i18n="memberAuthPasswordLinkSent") {{ $t('memberAuthPasswordLinkSent') }}

      button.btn.btn-submit-md(:disabled="resendLoading", @click="resendCode")
        span(v-if="resendLoading", data-i18n="formSending") {{ $t('formSending') }}...
        span(v-else-if="resendSuccess", data-i18n="formSent") {{ $t('formSent') }}!
        span(v-else, data-i18n="formResendCode") {{ $t('formResendCode') }}
      a.text-center.d-block.mt-3(data-i18n="goBack", @click="goTo('email')") {{ $t('goBack') }}
    template(v-else-if="stageIsCode")
      p(data-i18n="memberForgotPasswordCodeIntro") {{ $t('memberForgotPasswordCodeIntro') }}

      TextInput(id="password1", v-model="password1", type="password",:placeholder="$t('formNewPassword')", :v$="v$")
        template(#label)
          span(data-i18n="formNewPassword") {{ $t('formNewPassword') }}
        template(#validation="v")
          span(v-if="v?.v.$invalid && password1", data-i18n="formFieldInvalidPassword") {{ $t('formFieldInvalidPassword') }}
          span(v-else-if="!v?.v.required", data-i18n="formFieldRequiredError") {{ $t('formFieldRequiredError') }}

      TextInput(id="password2", v-model="password2", type="password", :placeholder="$t('formRepeatPassword')", :v$="v$")
        template(#label)
          span(data-i18n="formRepeatPassword") {{ $t('formRepeatPassword') }}
        template(#validation="v")
          p.my-0.pb-1(v-if="v?.v.sameAs.$invalid", data-i18n="formFieldPasswordNotTheSame") {{ $t('formFieldPasswordNotTheSame') }}
          p.my-0.py-0(v-if="v?.v.minLength.$invalid", data-i18n="formFieldInvalidPassword") {{ $t('formFieldInvalidPassword') }}
          span(v-else-if="v?.v.required.$invalid", data-i18n="formFieldRequiredError") {{ $t('formFieldRequiredError') }}

      SubmitButton#login-submit(:disabled="passwordLoading", :loading="passwordLoading", data-i18n="memberUpdateMyPassword", @click="updatePassword") {{ $t('memberUpdateMyPassword') }}

    Loading(v-else)
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { email, minLength, required, sameAs } from '@vuelidate/validators'
import cognitoMixin, { createAuthFeedback } from '@layers/web/mixins/cognito.mixin'
import statuses from '@layers/web/constants/statuses'
import contexts from '@layers/web/constants/contexts'
import { AuthPages } from '@layers/web/utils/auth/auth'

const stages = {
  preparing: 'preparing',
  email: 'email',
  code: 'code',
  success: 'success',
  resend: 'resend'
}

export default defineNuxtComponent({
  setup: () => ({ v$: useVuelidate() }),

  mixins: [cognitoMixin],

  validations () {
    return {
      email: { required, email },
      code: { required },
      password1: { required, minLengthFirst: minLength(8) },
      password2: { required, minLength: minLength(8), sameAs: sameAs(this.password1) },
    }
  },

  data () {
    return {
      email: '',
      code: '',
      password1: '',
      password2: '',

      stage: stages.preparing,

      emailLoading: false,
      passwordLoading: false,
      resendLoading: false,
      resendSuccess: false,
    }
  },

  computed: {
    stageIsEmail () {
      return this.stage === stages.email
    },

    stageIsCode () {
      return this.stage === stages.code
    },

    stageIsResend () {
      return this.stage === stages.resend
    }
  },

  created () {
    this._clearState()

    if (this.$router.currentRoute.value?.query?.aterstall) {
      if (this.$router.currentRoute.value?.query?.email) {
        this.code = this.$router.currentRoute.value.query.aterstall
        this.email = this.$router.currentRoute.value.query.email
        this.$router.replace({ query: null }).catch(() => {})

        this.stage = stages.code
        return
      }
    }
    this.stage = stages.email
  },

  methods: {
    findAccountByEmail () {
      this._clearState()
      this.v$.email.$touch()

      if (!this.v$.email.$dirty || this.v$.email.$invalid) {
        return
      }

      this.emailLoading = true
      this.cognitoForgotPassword(this.email)
        .then((x) => {
          this.emailLoading = false

          if (x.status === statuses.ok) {
            this.stage = stages.resend
          }
        })
    },

    updatePassword () {
      this._clearState()
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.passwordLoading = true
      this.cognitoForgotPasswordSubmit(this.email, this.code, this.password1)
        .then((x) => {
          this.passwordLoading = false

          if (x.status === statuses.ok) {
            this.setFeedback(createAuthFeedback(
              this.$t('memberSuccessfullyResetPassword'),
              'successfulPasswordReset',
              contexts.success
            ))
            this.$router.push(this.authLinkTo(AuthPages.login)).catch(() => {})
            this.stage = stages.success
          }
        })
    },

    async resendCode () {
      if (!this.email) {
        this.stage = stages.email
      }

      this.resendLoading = true
      this.resendSuccess = false
      const response = await this.cognitoForgotPassword(this.email)

      if (response.status === statuses.ok) {
        this.resendSuccess = true
        this.timer = window.setTimeout(() => {
          this.resendSuccess = false
        }, 2500)
      }

      this.resendLoading = false
    },

    goTo (stageKey) {
      this.stage = stages?.[stageKey] || stages.email
    },
  }
})
</script>
